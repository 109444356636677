<template>
    <zw-sidebar @shown="shown"
                :title="$t('common.form.title.select_supplier')"
    >
        <ValidationObserver tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="12">
                        <zw-model-selector-group v-model="offering.customer"
                                                 name="offering.customer"
                                                 model-type="customer"
                                                 validate="required"
                                                 :callback="customerSelected"
                                                 :additionalParams="{'type':this.payload.type}"
                        />
                    </b-col>

                    <b-col sm="12">
                        <zw-select-group name="offering.contactPerson"
                                         v-model="offering.contactPerson"
                                         :options="contactPersons"
                                         validate="required"
                                         text-field="fullName"
                                         value-field="id"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row align-h="end">
                    <b-col sm="12" class="text-sm-right text-xs-center">
                        <b-button block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </ValidationObserver>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import ValidateMixin from './../../mixins/validate'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'select-supplier',
    components: {ZwModelSelectorGroup},
    mixins: [ValidateMixin, commonSave],
    data() {
        return {
            loading: true,
            customersSearchUrl: window.apiUrl + '/customer-search',
            payload: {},
            contactPersons: [],
            customerForm: null,
            offering: {
                customer: null,
                contactPerson: null,
            }
        }
    },
    methods: {
        ...mapGetters('ContactPerson', ['getContactPersons']),
        ...mapGetters('Customer', ['getCustomer']),
        shown() {
            this.loading = false
        },
        customerSelected(customer) {
            this.$store.dispatch('ContactPerson/fetchContactPersonsList', customer.id)
                .then(() => {
                    this.contactPersons = this.getContactPersons()
                    this.offering.contactPerson = this.getContactPersons()[0].id
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Offering/addSupplier', {
                        'id': this.payload.offeringId,
                        'customerId': this.offering.customer,
                        'contactPersonId': this.offering.contactPerson,
                    }).then((response) => {
                        this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                        this.commonAfterSave(response)
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
}
</script>